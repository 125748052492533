<template>
    <div class="w-100">
        <vue-headful :title="$t('title_resource_room')" description="title" />

        <v-card flat class="mt-7  grey lighten-4">
            <v-container class="py-7 px-10">
                <v-row class="pa-0">
                    <v-col cols="12" class="pa-0">
                        <v-card-title class="text-h4 font-weight-bold px-2">{{
                            $t('resources.resources_room')
                        }}</v-card-title>
                    </v-col>
                    <v-col cols="12" class="pa-0 mb-4">
                        <BeeTabs
                            @changeTab="changeTab"
                            :items="items"
                            text-align="left"
                            :flat="false"
                            ref="beeTabs"
                        />
                    </v-col>

                    <v-col cols="12" class="pa-0 rounded-lg shadow-sm white">
                        <router-view />
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
    </div>
</template>

<script>
import BeeTabs from '../../components/core/BeeTabs'

export default {
    name: 'ResourceRoom',
    components: { BeeTabs },
    data() {
        return {
            tabsRoutes: [
                'activities',
                'teacher_training',
                'reading_progression_system',
            ],
        }
    },
    mounted() {
        this.findTabFromRoute(this.$route.path)
    },
    methods: {
        /**
         * Pushes route to the selected tab
         * @param tab tab selected
         */
        changeTab(tab) {
            this.$router.push(`${this.tabsRoutes[tab]}`)
        },
        /**
         * Finds and selects the tab for the current resource route
         * @param path resource route
         */
        findTabFromRoute(path) {
            const pageIndex = this.tabsRoutes.findIndex((tabPage) => tabPage === path.substr(path.lastIndexOf('/') + 1))
            this.$refs.beeTabs.selectTab(pageIndex)
        },
    },
    computed: {
        items() {
            return this.tabsRoutes.map((tabPage) => this.$t(`resources.${tabPage}`))
        },
    },
    watch: {
        /**
         * Listens to route path changes to update tab selection
         * @param newPath updated path from the URL
         */
        // eslint-disable-next-line object-shorthand
        '$route.path'(newPath) {
            this.findTabFromRoute(newPath)
        },
    },
}
</script>

<style scoped></style>
